import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppCoreRoutingModule } from './app-core.routing';

// REDUX affairs:
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// ... and to use the redux-store of this Main App Modules 'app-modules':
import { appModulesReducerName, appModulesReducers } from './redux-store/store-pack.reducers';
// import { AuthenticationEffects } from './redux-store/effects/authentication.effects';

// The UI/UX lib of COmponents:
import { NgXyzUiLibModule } from 'ng-xyz-ui-lib';

// Features Modules
import { TodosModule } from 'src/app/app-features/todos/todos.module';

// Core Components:
import { HomepageComponent } from './views/home/containers/homepage/homepage.component';
import { HomepageLoginComponent } from './views/home/contents/homepage-login/homepage-login.component';
import { HomepageHomeComponent } from './views/home/contents/homepage-home/homepage-home.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

// Services
import { AuthService } from './auth/firebase-auth.services';

@NgModule({
    imports: [
        // to enable Ng Directives like 'ngFor', '*ngIf', etc. on Ng Component's HTML templates
        CommonModule,
        // to enable 'routerLinkActive', 'routerLink', 'router-outlet', etc.
        RouterModule,
        // to enable any kind of Ng Forms manipulation
        ReactiveFormsModule, FormsModule,

        // Ngrx REDUX: ... forRoot() is only @ app.module.ts -> it's the bootstrap component; all the others are 'forFeature()'
        StoreModule.forFeature(appModulesReducerName, appModulesReducers),
        // EffectsModule.forFeature([AuthenticationEffects]),

        TodosModule,

        // ----------------
        NgXyzUiLibModule,
        // ----------------

        // BE CAREFULL with MODULE's import ORDER!
        AppCoreRoutingModule
    ],
    declarations: [
        HomepageComponent,
        HomepageLoginComponent,
        HomepageHomeComponent,
        UserProfileComponent,
        PageNotFoundComponent
    ],
    providers: [
        AuthService
    ],
    exports: [
        HomepageComponent,    // <= needed at 'app' root component, to its HTML template rendering of <core-homepage />
    ]
})
export class AppCoreModule { }
