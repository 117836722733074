<section class="default-content-page mt-4">
    <p>HTTP: <span>404</span></p>

    <code><span>this_page</span>.<em>not_found</em> = true;</code>
    <code>
        <span>if</span> (<b>you_spelt_it_wrong</b>)
            &#123; <span>try_again()</span>; &#125;
    </code>
    <code>
        <span>else if (<b>we_screwed_up</b>)</span>
        &#123; <em>console.warn</em>(<i>"We're really sorry about that..."</i>); <span>window</span>.<em>location</em> = home; home.<span>click()</span>; &#125;
    </code>

    <p class="text-center">
        <button class="btn btn-sm btn-outline-info mt-4" onclick="javascript: window.location='/';" title="Take me Home!">
            <i class="fa fa-long-arrow-left mr-3" aria-hidden="true"></i>
            <span style="font-family: 'Lato', Calibri, Arial, sans-serif">back to home</span>
        </button>
    </p>

</section>