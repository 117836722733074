import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';

import { User as FirebaseUser } from 'firebase';

@Component({
    selector: 'core-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    pageTitle = 'XYZ/user-profile';

    user$: Observable<FirebaseUser>;

    constructor(
        private browserTitleService: Title,
        public afAuth: AngularFireAuth,
    ) { }

    ngOnInit() {
        this.setBrowserTabTitle(this.pageTitle);
        this.user$ = this.afAuth.authState;
    }

    public setBrowserTabTitle( newTitle: string) {
        this.browserTitleService.setTitle( newTitle );
    }

}
