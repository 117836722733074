import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TodosComponent } from './todos.component';

import { AuthGuard } from 'src/app/app-core/auth/auth-guard.service';
import { AuthEmailGuard } from 'src/app/app-core/auth/auth-email-guard.service';

export const routes: Routes = [
    { path: 'todos', component: TodosComponent, canActivate: [AuthEmailGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class TodosRoutingModule { }
