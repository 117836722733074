<div class="logos-container text-center">

    <h1 class="app-title">{{ pageTitle }}</h1>

    <div>
        <img name="angular-logo" class="img-logos img-responsive strong-shadow" alt="Angular logo" src="assets/images/angular.png">
        <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
        <img name="firebase-logo" class="img-logos img-responsive strong-shadow" alt="Firebase logo" src="assets/images/firebase.png">
    </div>
    <div>
        <i class="fa fa-exchange fa-3x" aria-hidden="true"></i>
    </div>
    <div>
        <img name="angular-firebase-logo" class="img-logos img-responsive strong-shadow not-inline p-3" alt="Angular + Firebase logo" src="assets/images/angular-firebase.png">
    </div>

</div>