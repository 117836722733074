import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './app-core/views/home/containers/homepage/homepage.component';

const routes: Routes = [
    // { path: '', redirectTo: '/sign-in', pathMatch: 'full' }
    // we have now a proper '/' page, @ 'app.component.html', where User can login
    { path: '', component: HomepageComponent, pathMatch: 'full'},    // <= "pathMatch: 'full'" not to load PageNotFound at the same time

    // {
    //     path: 'todos',
    //     loadChildren: () => import('./app-features/todos/todos.module').then(m => m.TodosModule),
    //     canLoad: [AdminGuard]
    // },

    // { path: '**', component: PageNotFoundComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
