import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'core-homepage-home',
    templateUrl: './homepage-home.component.html',
    styleUrls: ['./homepage-home.component.scss']
})
export class HomepageHomeComponent implements OnInit {

    pageTitle = 'Welcome to XYZ Reality!';

    constructor(private browserTitleService: Title) { }

    ngOnInit() {
        this.setBrowserTabTitle(this.pageTitle);
    }

    private setBrowserTabTitle( newTitle: string) {
        this.browserTitleService.setTitle( newTitle );
    }

}
