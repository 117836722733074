import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable, Subscription } from 'rxjs';

import { FirebaseTodosService } from './services/firebase-todos.services';

import { Store } from '@ngrx/store';
import { IMainAppModulesState, IAuthenticatedUser } from 'src/app/app-core/redux-store/store-pack.state.interfaces';
import { authenticationOfAnUser } from 'src/app/app-core/redux-store/store-pack.selectors';

import { AuthService } from 'src/app/app-core/auth/firebase-auth.services';
import { AngularFireAuth } from '@angular/fire/auth';

import { User as FirebaseUser } from 'firebase';
import { FirebaseUser as OurFirebaseUser } from 'src/app/app-core/app-core.interfaces';
import { ItemWithId, Item } from './todos.interfaces';

@Component({
    selector: 'feature-todos',
    templateUrl: './todos.component.html',
    styleUrls: ['./todos.component.scss']
})
export class TodosComponent implements OnInit {

    // Wanna know what the hell is happening...?
    // =========================
    activateDebugConsole = false;
    // =========================

    pageTitle = 'XYZ/todos';

    // We can get our looged user UID, from the REDUX Store's 'authenticationOfAnUser' Object
    theAuth$: Observable<IAuthenticatedUser>;
    // Should we...? Where is the "single source of truth"...?
    loggedInUser: FirebaseUser; // OurFirebaseUser;
    loggedInUserSubscription: Subscription;

    // And its list of TODOs on Google's Cloud Firebase No-SQL DB:
    todos$: Observable<Array<ItemWithId>>;
    todosForThisUser$: Observable<Array<ItemWithId>>;

    // Filtering by ItemWithId prop: by 'done':
    filterDonePropTo: boolean = null;

    constructor(
        private browserTitleService: Title,
        public fbDBService: FirebaseTodosService,

        public auth: AuthService,
        public afAuth: AngularFireAuth,

        public store: Store<IMainAppModulesState>
    ) { }

    ngOnInit() {
        this.setBrowserTabTitle(this.pageTitle);

        // As HomepageLoginComponent assures any logged in User is now, besides on the (persistent) browser's Local Storage,
        // also on our REDUX (slice) Store, we can use it!
        this.theAuth$ = this.store.select(authenticationOfAnUser);

        // And the list of todos, for now, still fetched on Google's Firebase DB:
        this.todos$ = this.fbDBService.getTodos();
        // NOT ANYMORE! We're not supposed to see other people's TODOs ;-)

        // Get logged in user full properties, so we can get the TODOs that ONLY belong to him.
        // We have a problem: where should we got our Auth User?
        // From Google's Firebase, from browser's Local Storage or from REDUX State...?
        // Well... if they all are perfect sync, we can get it from any of the 3 places.
        // MIND YOU:
        // ====================================================
        // So, first, what we want to be sure, what we want to synchronize,
        // is the logged User at Google's Firebase with our browser's Local Storage one:
        // ====================================================

        // this.loggedInUserSubscription = this.theAuth$.subscribe(
        //  (authoringState: IAuthenticatedUser) => {
        //      const reduxStoreLoggedUser: OurFirebaseUser = authoringState ? authoringState.authData : null;

        // const firebaseUser$: Observable<FirebaseUser> = this.afAuth.authState;
        // this.loggedInUserSubscription = firebaseUser$.subscribe(
        //     (user: FirebaseUser) => {

        //         if (!this.auth.checkLoginSyncFirebaseVsLocalStorage(user /* reduxStoreLoggedUser*/)) {
        //             this.loggedInUser = null;
        //         } else {
        //             this.loggedInUser = user; // reduxStoreLoggedUser;

        //             // ====================================================
        //             // Finally, after we've checked sync Authoring,
        //             // we can use cleaned and super usefull to render, OurFirestoreUser!
        //             // ====================================================
        //             const cleanedUser = user.toJSON() as OurFirebaseUser;
        //             // this.todos$ = this.fbDBService.getTodos(cleanedUser);
        //             // this.todosForThisUser$ = this.fbDBService.getTodos(cleanedUser);

        //             this.fbDBService.getFilteredAuthTodos(cleanedUser);
        //             this.fbDBService.authTodos$.next(cleanedUser.uid);
        //             // this.todosForThisUser$ = this.fbDBService.authDoneFilteringTodos$;
        //             this.todos$ = this.fbDBService.authDoneFilteringTodos$;
        //             // ====================================================
        //         }
        //         // We have what we want!
        //         // We don't need anything more from this subscription - just the once triggering of a REDUX Action.
        //         if (this.loggedInUserSubscription) {
        //             this.loggedInUserSubscription.unsubscribe();
        //         }
        //     }
        // );
    }

    public setBrowserTabTitle( newTitle: string) {
        this.browserTitleService.setTitle( newTitle );
    }

    public onTodoCreation(newTodo: Item, firebaseUserId: string) {
        this.fbDBService.createTodo(
            Object.assign( {}, newTodo, { userId: firebaseUserId} )
        );
    }

    public onTodoManipulation(todoManipulated: { action: string; item: ItemWithId; propToUpdate: { [key: string]: string | boolean } }) {
        // console.warn('Manipulate TODO:', todoManipulated);

        switch (todoManipulated.action) {
            case 'update':
                this.fbDBService.updateTodo(todoManipulated.item, todoManipulated.propToUpdate);
                break;
            case 'delete':
                this.fbDBService.deleteTodo(todoManipulated.item);
                break;

            default:
                console.error(
                    `onTodoManipulation() ERROR: coming Action ("${todoManipulated.action}") is not known... Please code it, first!`
                );
                break;
        }
    }

    /**
     * Used at the Template's markup, a button should be able to:
     *  * get a list with only the 'todo's
     *  * with all the others - the already marked by the user as 'done' ones
     *  * and, off course, the clean list with them all mixed up
     *
     * @param what - optional; if nothing is to be filtered... than who ever calls this get the whole lot! ;-)
     */
    public filterItemsList(what: 'todos' | 'dones' | 'all') {
        // Previously:
        // this.todos$ = this.fbDBService.getTodos(this.loggedInUser, what);
        // this.todos$ = this.fbDBService.getFilteredAuthTodos(this.loggedInUser, what);
        // Which is wrong, by the previous commit explanations...

        // // Let's do it "by the book", found here:
        // // https://github.com/angular/angularfire/blob/master/docs/firestore/querying-collections.md

        // const fbUserId: string = ofThisUser.uid;
        // const doneBool: boolean = what === 'todos' ? false : true;

        // // Trigger the update of the filtering/query to do on the "plain" collection of TODOs:
        // // this.fbDBService.getFilteredAuthTodos(ofThisUser, what);

        // // Now we have the DB Query set, effectively filter the TODOS collection with it, geting a new filtered collection:
        // this.fbDBService.authTodos$.next(fbUserId);
        // this.fbDBService.doneTodos$.next(doneBool);
        // // ... that should be now on the 'firebase-todos.service.ts' GLOBAL var of the (filtered) todo$:
        // this.todos$ = this.fbDBService.authDoneFilteringTodos$;

        switch (what) {
            case 'todos':
                this.filterDonePropTo = false;
                break;
            case 'dones':
                this.filterDonePropTo = true;
                break;
            case 'all':
                // No filter
                this.filterDonePropTo = null;
                break;
            default:
                break;
        }
    }
}
