<div id="wrapper">

    <div id="sidebar-wrapper">

        <div class="homepage-login-container">
            <i class="fa fa-times-circle-o fa-2x pull-right close-menu text-info" (click)="toggleMenu($event)"></i>
            <core-homepage-login (userHasSignIn)="onUserSignIn()" (userHasSignOut)="onUserSignOut()"></core-homepage-login>
        </div>

    </div>

    <div id="page-content-wrapper">
        <div class="container">

            <xyz-buttons-flex-bar [buttonsDisabled]="topMenuDisabledButtons" (toggleMenu)="toggleMenu()"></xyz-buttons-flex-bar>
            
            <!-- this placement is the key for having different renderings for the entire App -->
            <router-outlet></router-outlet>
            <!-- this placement is the key for having different renderings for the entire App -->

        </div>
    </div>

</div>