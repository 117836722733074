import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

// import { AngularFireAuth } from '@angular/fire/auth';
import { User as FirebaseUser } from 'firebase';

import { AuthService } from 'src/app/app-core/auth/firebase-auth.services';

// REMEMBER @ 'src\app\app-core\auth\firebase-auth.services.ts's Method 'setUserData()',
// we had to do "const cleanedUser = user.toJSON() as OurFirebaseUser;"?
// Meaning FirebaseUsers form Firebase is not exactly iqual to OUR FirebaseUser, we store in the Redux Store and print on '/profile'
// And Typescript detects it very severely! Good catch...
import { User, FirebaseUser as OurFirebaseUser, FormValues } from 'src/app/app-core/app-core.interfaces';

@Component({
    selector: 'core-homepage-login',
    templateUrl: './homepage-login.component.html',
    styleUrls: ['./homepage-login.component.scss']
})


export class HomepageLoginComponent implements OnInit {

    welcomeMsg = 'Welcome to XYZ!';
    loginMsg = 'Please login either anonymously or by using one of the authorities below.';

    user$: Observable<FirebaseUser>;
    userSubscription: Subscription;

    // '/profile' will render the entire 'FirebaseUser' User properties.
    // Here, at 'homepage-login' (well.... not exactly here but in its "dumb" child component, <xyz-user-card /> component)
    // we only render a few, most significative ones, stored at browser's Local Storege:
    locallyStoredUser: User;

    // set to true when password was reseted @ Google's Firebase
    // UI/UX will be triggered, then, at the hosted <xyz-auth-form /> component:
    passwordReset = false;

    @Output() userHasSignIn = new EventEmitter<Observable<FirebaseUser>>();
    @Output() userHasSignOut = new EventEmitter();

    constructor(
        public auth: AuthService,
        // public afAuth: AngularFireAuth,
        public router: Router,
    ) { }

    ngOnInit() {

        // this.user$ = this.afAuth.authState;
        // this.user$ = this.auth.afAuth.authState;
        //
        // -----------------------------------------------------------------------------
        // In order to Jasmin Unit Test do the call to this componnet's PROPERTY 'user$', and test it,
        // we need to be able to call, from the '.spec' test code, a spy on some mocked up 'AuthService'!
        // So move the value attribution to 'AuthService', and, then, call here the Service method, instead:
        // -----------------------------------------------------------------------------
        this.user$ = this.auth.getFirebaseAuthState();

        // And, on the same way, this:
        // this.locallyStoredUser = JSON.parse(localStorage.getItem('user'));
        // can be done by calling this, instead:
        this.locallyStoredUser = this.auth.getLocalStorageUser();

        // We could be dealing with a refresh/reload from the top.
        // We have to account for the possibility of the User being already authenticated, with some login in the pass,
        // and, at the bootstrap of the Application / refresh of the page, Firebase already has it authenticated but we don´t
        //
        // Get the 'source of truth' - Google's - and update any needed synchronization!
        this.checkGoogleAuth();

    }

    /**
     * Ordinary Sign Up that should produce an instant SignIn, but still without email validation
     *
     * @param userFormParams - user inputted name, email and password @ <user-form />
     */
    public onUserSignUp(userFormParams: FormValues) {
        this.commonLoginAffairs();
        this.auth.signUp(userFormParams.name, userFormParams.email, userFormParams.password).then( () => this.refreshUserDataAllOver());
    }

    /**
     * Allowed SignIn through authorized authority @ Firebase console configuration
     *
     * @param authority - the id of the authority through which he user will be signing in (shuld fill the allowed credentials on a pop-up)
     */
    public onUserAuthSignIn(authority: string) {
        this.commonLoginAffairs();

        switch (authority) {
            case 'google':
                this.auth.googleLogin().then( () => this.refreshUserDataAllOver());
                break;
            case 'anonymous':
                this.auth.anonymousLogin().then( () => this.refreshUserDataAllOver());
                break;

            // Once all the others are setup @ Firebase's console,
            // DO NOT forget to call, HERE, each of the corresponding 'this.auth' Service 'case'!

            default:
                break;
        }

    }

    /**
     * Ordinary Sign In, through the input of Signed Up's mail + password
     *
     * @param userFormParams - user inputted name, email and password @ <user-form />
     */
    public onUserSignIn(userFormParams: FormValues) {
        this.commonLoginAffairs();
        this.auth.signIn(userFormParams.email, userFormParams.password).then( () => this.refreshUserDataAllOver());
    }

    /**
     * User has asked to reset it's password, from <user-form /> component
     *
     * @param userFormParams - user inputted name, email and password @ <user-form />
     */
    public onUserResetPasswordRequest(userFormParams: FormValues) {
        this.auth.forgotPassword(userFormParams.email).then( () => this.passwordReset = true);
    }

    /**
     * User wants to SIGN OUT, requesting it from <xyz-user-card /> component.
     * Spread the word:
     *   1) Sign it out on Firebase
     *   2) and, when done's received, emit back to the host parent component (needs to do some UX, based on the logged out action)
     *
     * -----------
     * MIND YOU
     * -----------
     * Once you "Sign it out on Firebase"
     * => Observable 'this.afAuth.authState;' will return null
     * => "this.user$ = this.afAuth.authState;" will turn this 'user$' Observable var into a null.
     * But we have no way to pass it back, to 'app.component', on "that" UX moment - user clicking on "Log out" button.
     *
     * We could set the same 'this.afAuth.authState;' Observer there, on the parent, but then you'd have another problem:
     *     synchronize 2 (iqual) Observers in DIFFERENT components, with the user action (Firebase actions are of course, all async)
     * So, I think best way is to deal, internally, with user actions, and, in parallel,
     * Firebase will deal with it's own affairs and produce results, back to this App, in an async way,
     * whenever ready/resolved => we just have to let it flow; no sync is, in fact, needed to be "forced", between Firebase and this App UX.
     *
     * Resuming, this 'homepage-login' component is the dispatcher with Firebase, and will coordinate UX between parent 'app.component'
     * and children (dumb) <xyz-user-card /> component.
     * That's precisely why later was moved to our 'ng-xyz-ui-lib.interfaces' library of UI/UX components, as so <xyz-auth-form />,
     * and this <homepage-login /> has to stay "here", NOT moving to the UI/UX lib.
     *
     * And, as a result, 'app.component' got rid of any conexion with Firebase! ;-) Beautiful!
     */
    public onUserSignOut() {
        this.auth.signOut().then( () => this.userHasSignOut.emit());
    }

    // ==============
    // AUX Methods
    // ==============

    /**
     * Once (imediatly before...) any new login/signup (auto login) happens
     * we must clean current browser's local storage, concerning authoring,
     * and "reset" to false the 'passwordReset' boolean - might just had been used prior to this current login that will happen...
     *
     */
    private commonLoginAffairs() {
        this.locallyStoredUser = null;
        this.passwordReset = false;
    }

    /**
     * A (new) login just happened - refresh the logged in User Data
     *   1) on the browser's local storage
     *   2) on the host parent component (needs to do some UX, based on the logged in user Data)
     */
    private refreshUserDataAllOver() {
        this.locallyStoredUser = JSON.parse(localStorage.getItem('user'));
        this.userHasSignIn.emit(this.user$);
    }

    /**
     * Check IF the user we have on the browser's local storage cookie
     * is the same as the one logged on Google's Firebase Auth.
     * Meanwhile logg our current loacal storage user into the Redux sliced Store selector for it.
     *
     * If it's not the same, we 'banUserLocally()':
     * => delete current browser's local storage (this.locallyStoredUser) and current user (this.user$).
     * => current user will see nothing on the login panel, as long as there is still "someone" logged at Google's Firebase.
     * Once you delete that authoring - on Firebase's console - user will be, freshly, prompted to login again
     */
    private checkGoogleAuth() {
        // Do we have a User on browser' local storage...?
        if (this.locallyStoredUser) {
            console.warn('Jasmine Unit Test:', this.locallyStoredUser);

            // Do we have an user on the Appplication? Already logged in?
            if (this.user$) {

                // OK. Let's check sync with Google's Firebase Authoring:
                this.userSubscription = this.user$.subscribe(
                    (user: any /* FirebaseUser but, actually as OurFirebaseUser*/) => {

                        // Redux Store will always start from the scratch, when App get's loaded from the top.
                        // So set, into 'app-core' Redux Store ('app-core/redux-store'), the Google's Firebase logged IN User data
                        this.auth.logginUserInReduxStore(user);

                        // Check it's real Authoring, the fact that we have trust 'this.locallyStoredUser' is with 'someone':
                        // const isLoggedUsersSync = this.auth.checkLoginSyncFirebaseVsLocalStorage(user);
                        if (!this.auth.checkLoginSyncFirebaseVsLocalStorage(user)) {
                            this.banUserLocally();
                        }

                        // We have what we want!
                        // We don't need anything more from this subscription - just the once triggering of a REDUX Action.
                        if (this.userSubscription) { this.userSubscription.unsubscribe(); }
                    }
                );
            }

        }
    }

    /**
     * Ban from the browser the current logeed in User, on local storage.
     * => delete current browser's local storage (this.locallyStoredUser) and current user (this.user$).
     */
    private banUserLocally() {
        console.warn('Jasmine Unit Test:', 'No sync between browser and Google\'s logged in User!', );
        this.user$ = null;
        this.locallyStoredUser = null;
        // Upper check already did house cleaning for local storage and REDUX Store.
        // So we only need to clean these component's globals.
    }
}
