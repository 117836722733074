import { ECheckAuthActions, CheckAuthActions } from '../actions/authentication.actions';
import { IAuthenticatedUser } from '../store-pack.state.interfaces';

export const authenticationReducers = (state: IAuthenticatedUser = null, action: CheckAuthActions): IAuthenticatedUser => {
    switch (action.type) {
        case ECheckAuthActions.LOGIN_COMPLETE:
            return {
                ...state,
                authData: action.payload,
            };
        case ECheckAuthActions.LOGGING_OUT:
            return {
                ...state,
                authData: null,
            };

        default:
            return state;
    }
};
