// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    firebaseConfig: {
        apiKey: 'AIzaSyDRb0e4QO6fEQAXtGm4kcHSC1j9maaFNC4',
        authDomain: 'xyz-reality-todos.firebaseapp.com',
        databaseURL: 'https://xyz-reality-todos.firebaseio.com',
        projectId: 'xyz-reality-todos',
        storageBucket: 'xyz-reality-todos.appspot.com',
        messagingSenderId: '587666944293',
        appId: '1:587666944293:web:c30eef6823c7204016eacc'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
