
<!-- user$: <pre>{{ (user$ | async) | json }}</pre> -->

<ng-container *ngIf="!(user$ | async)">
	<h2 class="text-right"><div class="welcome-msg badge badge-info">{{ welcomeMsg }}</div></h2>
	<p class="welcome-msg-description text-justify">{{ loginMsg }}</p>

	<div class="auth-buttons-container">
		<button title="Login with your Google's credentials!"                            (click)="onUserAuthSignIn('google')" class="btn btn-danger"><i class="fa fa-google mr-2"></i> Google</button>
		<button disabled title="Authoring still to implement on Firebase's console..."   (click)="onUserAuthSignIn('github')" class="btn btn-dark"><i class="fa fa-github fa-lg mr-2"></i> GitHub</button>
		<button disabled title="Authoring still to implement on Firebase's console..."   (click)="onUserAuthSignIn('facebook')" class="btn btn-primary"><i class="fa fa-facebook fa-lg mr-2"></i> Facebook</button>
		<button disabled title="Authoring still to implement on Firebase's console..."	 (click)="onUserAuthSignIn('twitter')" class="btn btn-warning text-white"><i class="fa fa-twitter fa-lg mr-2"></i> Twitter</button>
		<button title="Fast login anonymously, without having to input any credentials!" (click)="onUserAuthSignIn('anonymous')" class="btn btn-outline-secondary my-5"><i class="fa fa-user-secret fa-lg mr-2"></i> Login Anonymously</button>
	</div>
	<hr>
	<div class="sign-in-up-buttons-container">	
		<xyz-auth-form
			(userHasSignUp)="onUserSignUp($event)"
			(userHasSignIn)="onUserSignIn($event)"
			(userWantsToResetPassword)="onUserResetPasswordRequest($event)"
			[passwordHasBeenReset]="passwordReset"
		></xyz-auth-form>
	</div>

</ng-container>

<ng-container *ngIf="(user$ | async) && locallyStoredUser">
	<h2 class="text-right"><div class="badge badge-info">Welcome back!</div></h2>
	<xyz-user-card [user]="locallyStoredUser" (goToProfile)="router.navigate(['/profile'])" (loggout)="onUserSignOut()"></xyz-user-card>
	<hr>
</ng-container>

