import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
    selector: 'core-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

    nativeDOM: HTMLElement;

    topMenuDisabledButtons = [false, false, false, false, true];

    constructor(
        private compDOM: ElementRef
    ) {}

    ngOnInit() {
        this.nativeDOM = this.compDOM.nativeElement;
        // See if any top bar button is to enable/disable:
        const isToDisable = JSON.parse(localStorage.getItem('user')) ? false : true;
        this.istoDisableTopButtons(isToDisable, 2);
        this.istoDisableTopButtons(isToDisable, 3);

        // Start with left side menu OPEN ...? Not so cool on mobiles... I think!
        // this.toggleMenu();

        // Test we can switch on/off all buttons:
        // this.istoDisableTopButtons(true);
    }

    toggleMenu(evt?: KeyboardEvent) {
        // console.warn(evt.target, evt.currentTarget, ' ... was clicked!');
        const wrapper: HTMLElement = this.nativeDOM.querySelector('#wrapper');
        wrapper.classList.toggle('toggled');
    }

    /**
     * Some "guest" has become an authorized User at the App!
     * => ENABLE the guarded route's button(s)
     */
    onUserSignIn() {
        this.istoDisableTopButtons(false, 2);
        this.istoDisableTopButtons(false, 3);
    }

    /***
     * User has been logged out from Firebase, Local Storage and REDUX Store
     * => DISABLE the guarded route's button(s)
     */
    onUserSignOut() {
        this.istoDisableTopButtons(true, 2);
        this.istoDisableTopButtons(true, 3);
    }

    /**
     * Enable/disable each/all the top menu bar of Buttons
     * that give user access to routes, some of them protected
     *
     * @param itIs - a vlue of 'true' will disable it/them; a value of 'false' will enable it/them
     * @param onWichButton - when specified, you only enable/disable this item on the 'topMenuDisabledButtons' aray of buttons
     */
    private istoDisableTopButtons(itIs: boolean, onWichButton: number = null) {
        if (onWichButton) {
            this.topMenuDisabledButtons[onWichButton] = itIs;
        } else {
            const toggledButtons = [];
            this.topMenuDisabledButtons.forEach((button: boolean, idx: number) => {
                button = itIs;
                toggledButtons.push(button);
                //
                if (idx === this.topMenuDisabledButtons.length - 1) {
                    this.topMenuDisabledButtons = toggledButtons;
                }
            });
        }
    }
}
