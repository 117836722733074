import { Injectable } from '@angular/core';

import {
    AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument,
    DocumentData, DocumentReference
} from '@angular/fire/firestore';

import { Observable, of, BehaviorSubject, combineLatest, PartialObserver } from 'rxjs';
import { switchMap, map, withLatestFrom, tap, finalize, catchError } from 'rxjs/operators';

import { Item, DocumentChangeAction, ItemWithId } from '../todos.interfaces';
import { FirebaseUser } from 'src/app/app-core/app-core.interfaces';

@Injectable({
    providedIn: 'root'
})
export class FirebaseTodosService {

    // Our Db collection of "items" (documents) TODOs
    itemsCollection = {} as DocumentData as AngularFirestoreCollection<Item>;

    // Our Auth User Array of TODOs:
    userAuthdocs: Array<ItemWithId> = [];
    // Our Auth User, and filtered, Array of TODOs:
    authDoneFilteringTodos$: Observable<Array<ItemWithId>>;
    authTodos$: BehaviorSubject<string | null>;
    doneTodos$: BehaviorSubject<boolean | null>;

    /**
     * Nice OFFICIAL reading for Firebase DATA manipulation:
     * https://github.com/angular/angularfire2/blob/7eb3e51022c7381dfc94ffb9e12555065f060639/docs/firestore/collections.md
     *
     * @param db - our No-SQL DB at Google's Cloud Firebase
     * https://console.firebase.google.com/project/xyz-reality-todos/database/firestore
     *
     */
    constructor(private db: AngularFirestore) { }

    /**
     * The Google's Cloud Firebase is a No-SQL Database, so a non related collection of Objects (what many people call WRONGLY a JSON! ;-)
     * Having said that, each DB Item (a TODO) is considered a document.
     *
     * If you need the doc.id() in the TODO Item, so it can be updated/deleted, you MUST query with '.snapshotChanges()'
     * '.snapshotChanges()' will then return an array of 'DocumentChangeAction's,
     * which contains a lot of information about "what happened" with each change on this 'document' / 'DB Item'
     *
     * Each 'DocumentChangeAction' is, so, an Object with 2 props:
     *  - type: what DocumentChangeType operation occured (added, modified, removed)
     *  - payload: important metadata about the change and a doc property which is the DocumentSnapshot.
     *
     * So, let's query our DB!
     *
     * KEEP IN MIND
     * -----------------------------
     * GetTodos PER LOGGED IN User
     * (filtered through doc.userId)
     * -----------------------------
     * =========================================
     *
     * REPLACED BY next 'getAuthTodos()' and 'getFilteredAuthTodos()'
     * NOPES!
     *
     * @param filter - optional. If any ItemWithId prop ('done' true/false) is to be filtered from
     * @param ofThisUser - optional. The current logged in User
     */
    getTodos(ofThisUser?: FirebaseUser, filter?: 'todos' | 'dones' | null): Observable<Array<ItemWithId>> {

        this.itemsCollection = this.db.collection<Item>('todos');

        const docState$: Observable<Array<DocumentChangeAction>> = this.itemsCollection.snapshotChanges();
        const docs$ = docState$.pipe(
            map(
                (docChanges: any) => docChanges.map(
                    (docAction: DocumentChangeAction) => {
                        const data = docAction.payload.doc.data() as Item;
                        const autoID: string = docAction.payload.doc.id;
                        // And so:
                        const doc = { autoID, ...data };

                        // // Now... we (better; the user!) can have a:
                        // const filteredDoc: ItemWithId = filter ? this.filteredDoc(filter, doc) : doc;
                        // // But WE shon't forget, lastly the:
                        // const docToReturn: ItemWithId = ofThisUser ? this.userAuthDoc(ofThisUser, filteredDoc) : doc;

                        // return docToReturn;
                        return doc;
                    }
                )
            )
            // , tap( (docs) => {
            //     console.warn('Doc of', ofThisUser, docChanges);
            //     this.userAuthdocs.push(docs);
            // })
            , catchError((error: Error) => of(error))
        );

        // // Check what, and when, we are (scalar) returning back:
        // console.warn('Docs of', ofThisUser, this.userAuthdocs);
        // // Remove the falsys (empty, null, undefined, etc) docs, result of filtering or authoring:
        // const validUserAuthDocs = this.userAuthdocs.filter(n => n);
        // console.warn('VALID Docs of', ofThisUser, validUserAuthDocs);

        return docs$;
        //
        // // ==================================================================================
        // // In order for Jasmine Unit Test to work,  => App will STOP working... Do 'jasmineGetTodos()' Method!
        // // ==================================================================================
        // // we can fetch directly the array of DocumentChangeAction:
        // const docs$: Observable<Array<Item>> = this.itemsCollection.valueChanges();
        // // ==================================================================================
        // // ... and we don't need to subscribe(), unless we want to check something...
        // //
        // // MIND YOU:
        // // ==================================================================================
        // // it will have a COST, to the final Jasmin Unit Test COVERAGE,
        // // if we leave next subscribe() lines UNcommented, for Jasmine to spy on them...
        // // ==================================================================================
        // //
        // // docs$.subscribe(
        // //     (docAction: any) => {
        // //         const data = docAction.payload.doc.data() as Item;
        // //         const autoID: string = docAction.payload.doc.id;
        // //         // And so:
        // //         const doc = { autoID, ...data };

        // //         // // Now... we (better; the user!) can have a:
        // //         // const filteredDoc: ItemWithId = filter ? this.filteredDoc(filter, doc) : doc;
        // //         // // But WE shon't forget, lastly the:
        // //         // const docToReturn: ItemWithId = ofThisUser ? this.userAuthDoc(ofThisUser, filteredDoc) : doc;

        // //         // return docToReturn;
        // //         return doc;
        // //     }
        // // );
        // return docs$ as Observable<Array<ItemWithId>>;

    }

    jasmineUnitTestGetTodos() {
        this.itemsCollection = this.db.collection<Item>('todos');
        return Object.keys(this.itemsCollection).length > 0 ? this.itemsCollection.valueChanges() : null;
    }

    // getAuthTodos() {

    // }

    /**
     * =========================================
     *     NEXT IS NOT BEING USED ANYMORE!
     * =========================================
     *
     * @param ofThisUser - the current logged in User
     * @param filter - if any ItemWithId prop ('done' true/false) is to be filtered from
     */
    // getFilteredAuthTodos(ofThisUser: FirebaseUser, filter?: 'todos' | 'dones' | null) {
    //     const fbUserId = ofThisUser.uid;
    //     const doneBool = filter === 'todos' ? false : true;

    //     this.authTodos$ = new BehaviorSubject(null);
    //     this.doneTodos$ = new BehaviorSubject(null);

    //     this.authDoneFilteringTodos$ = combineLatest(
    //         [this.authTodos$, this.doneTodos$]
    //     ).pipe(
    //         switchMap(([userId, done]: [string, boolean]) =>
    //             this.db.collection('todos', ref => {
    //                 let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    //                 if (userId) { query = query.where('userId', '==', fbUserId); }
    //                 if (done) { query = query.where('done', '==', doneBool); }
    //                 return query;
    //             }).valueChanges()
    //         )
    //     ) as any;
    // }

    createTodo(newTodo: Item) {
        this.itemsCollection.add(newTodo).catch((error: Error) => this.handleError);
    }

    updateTodo(manipulatedTodo: ItemWithId,  onProp: { [key: string]: string| boolean}) {
        const DBdoc: AngularFirestoreDocument<ItemWithId> = this.db.doc(`todos/${manipulatedTodo.autoID}`);
        DBdoc.update(onProp).catch((error: Error) => this.handleError);

        // If something seems wrong, next is better for TESTING: Check it @ https://firebase.google.com/docs/firestore/manage-data/add-data
        // If the item does not exists, it just gets created with this single prop  - you can then inspect at the Firebase Console!
        // DBdoc.set(onProp, { merge: true }).catch((error: Error) => this.handleError);
    }

    deleteTodo(manipulatedTodo: ItemWithId) {
        const DBdoc: AngularFirestoreDocument<ItemWithId> = this.db.doc(`todos/${manipulatedTodo.autoID}`);
        DBdoc.delete().catch((error: Error) => this.handleError);
    }

    /**
     * =========================================
     *     NEXT IS NOT BEING USED ANYMORE!
     * =========================================
     *
     * User has the feature of filtering the TODOs listing,
     * on each of the boolean state they can have: docs 'todo' or 'done'
     *
     * @param thisFilter - the wanted filtering, coming fom the Web Surfer user UX
     * @param thisDoc - the current Doc we are analysing, on Firebase DB to send it to the list of TODODs or not.
     */
    // private filteredDoc(thisFilter: 'todos' | 'dones' | null, thisDoc: ItemWithId): ItemWithId {
    //     const emptyDoc = {} as ItemWithId;

    //     if (thisFilter === 'todos') {
    //         return thisDoc.done === true ? emptyDoc : thisDoc;
    //     } else if (thisFilter === 'dones') {
    //         return thisDoc.done === true ? thisDoc : emptyDoc;
    //     }

    // }

    /**
     * =========================================
     *     NEXT IS NOT BEING USED ANYMORE!
     * =========================================
     *
     * It's a business rule: each user can only see, and manipulate, their own TODOs list
     *
     * @param belongingToThisUser - our logged in Firebase User
     * @param thisDoc - the current Doc we are analysing, on Firebase DB to send it to the list of TODODs or not.
     */
    // private userAuthDoc(belongingToThisUser: FirebaseUser, thisDoc: ItemWithId): ItemWithId {
    //     const emptyDoc = {} as ItemWithId;
    //     return thisDoc.userId === belongingToThisUser.uid ? thisDoc : emptyDoc;
    // }

    private handleError(error: Error) {
        console.error('==============================');
        console.warn('A Firebase Service ERROR occurred: ', error.message);
        console.error('==============================');
    }

}
