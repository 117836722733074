import { Action } from '@ngrx/store';
import { FirebaseUser as OurFirebaseUser } from 'src/app/app-core/app-core.interfaces';


export enum ECheckAuthActions {
    LOGIN_COMPLETE = '[app-core/auth] User auth by Firebase SUCCESSFULLY!',
    LOGGING_OUT = '[app-core/auth] User has logged out SUCCESSFULLY...'
}


export class LoginComplete implements Action {
    readonly type = ECheckAuthActions.LOGIN_COMPLETE;
    constructor(public payload: OurFirebaseUser) { }
}


export class Logout implements Action {
    readonly type = ECheckAuthActions.LOGGING_OUT;
    constructor() { }
}

export type CheckAuthActions = LoginComplete | Logout;
