import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageHomeComponent } from './views/home/contents/homepage-home/homepage-home.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

import { AuthGuard } from './auth/auth-guard.service';
import { AuthEmailGuard } from './auth/auth-email-guard.service';

// We should be loading a Module, for lazy loading...
import { TodosComponent } from '../app-features/todos/todos.component';

export const routes: Routes = [

    { path: '', children: [
            { path: '', component: HomepageHomeComponent },
            { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
            // {
            //     path: 'todos',
            //     loadChildren: () => import('../app-features/todos/todos.module').then(m => m.TodosModule),
            //     canLoad: [AdminGuard]
            // },
            // Since we have upgrade to Angular 9, code does not break but, in fact, we are not loading with laziness this '/todos' Module.
            // The way lazy loading is done now, it's completely different than in old Angular 8 versions...
            // So:
            // { path: 'todos', component: TodosComponent, canActivate: [AuthEmailGuard] },
            //
            // is on |todos| Module's Routing list

        ]
    },
    { path: '**', component: PageNotFoundComponent }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, AuthEmailGuard]    // AuthEmailGuard is needed for the Feature Module |todos| Routing
})

export class AppCoreRoutingModule { }
