import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

import { User as FirebaseUser } from 'firebase';
import { User } from 'src/app/app-core/app-core.interfaces';
// import { User, FirebaseUser } from 'src/app/app-core/app-core.interfaces';
// REMEMBER bellow @ 'setUserData()' Method, we had to do "const cleanedUser = user.toJSON() as OurFirebaseUser;"?
// Meaning FirebaseUsers form Firebase is not exactly iqual to OUR FirebaseUser, we store in the Redux Store and print on '/profile'
// And Typescript detects it very severely! Good catch...
import { FirebaseUser as OurFirebaseUser, FbAuthType } from 'src/app/app-core/app-core.interfaces';

import { Store } from '@ngrx/store';
import { IMainAppModulesState } from '../redux-store/store-pack.state.interfaces';
import { LoginComplete, Logout } from '../redux-store/actions/authentication.actions';

@Injectable({
    // Next enables us to NOT declare this Service in 'app.module' under "providers: []"
    // => enables "Tree Shaking" - service won't be on build, if not used/needed anymore
    providedIn: 'root'
})

export class AuthService {

    currentAuthoredFirebaseUser: firebase.User | null = null;

    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public router: Router,
        public store: Store<IMainAppModulesState>
    ) {  }

    /**
     * -----------------------------------------------------------------------------
     * In order to Jasmin Unit Test do the call to 'homepage-login' componnet's PROPERTY 'user$', and test it,
     * we need to be able to call, from the '.spec' test code, a spy on some mocked up 'AuthService'!
     * So move the value attribution to 'AuthService', and, then, call here the Service method, instead:
     * -----------------------------------------------------------------------------
     */
    getLocalStorageUser(): User {
        return JSON.parse(localStorage.getItem('user'));
    }
    // And, on the same way, and also by 'homepage-login' component's PROPERTY 'afAuth.authState'
    getFirebaseAuthState(): Observable<FirebaseUser> {
        return this.afAuth.authState;
    }
    // In the same line of hought, we have 'this.afAuth.auth' property of AngularFireAuth.
    // used many times on this same AuthService code,
    // a Service from OUR App - that calls a service from Google's Firebase (this.afAuth) that has a few used methods/props:
    getFirebaseAuthInstance(): FirebaseAuth {
        // By the way, if anyone comes here to grab 'this.afAuth.auth' it's becuse it is expecting someone is Authored on Google's Firebase.
        // Let's then, first, update our Glogal 'currentAuthoredFirebaseUser' to the latest on Google:
        this.currentAuthoredFirebaseUser = this.afAuth.auth ? this.afAuth.auth.currentUser : null;

        return this.afAuth.auth;
    }

    /**
     * Let the user sign in through (some of) the Google's auth providers:
     */
    googleLogin(): Promise<any> {
        return this.authLogin(new firebase.auth.GoogleAuthProvider());
    }
    githubLogin(): Promise<any> {
        return this.authLogin(new firebase.auth.GithubAuthProvider());
    }
    facebookLogin(): Promise<any> {
        return this.authLogin(new firebase.auth.FacebookAuthProvider());
    }
    twitterLogin(): Promise<any> {
        return this.authLogin(new firebase.auth.TwitterAuthProvider() as firebase.auth.GoogleAuthProvider);
    }

    /**
     * Anonymous login (no credentials, no OAuth...)
     */
    anonymousLogin(): Promise<FbAuthType> {
        const promiseToSignInAnonymously: Promise<any> = this.getFirebaseAuthInstance().signInAnonymously();

        promiseToSignInAnonymously
            .then((result: any) => this.setUserData(result.user))
            .catch(this.handleError)
        ;

        return promiseToSignInAnonymously;
    }

    /**
     * Sign up with email/password.
     *
     * Call the SendVerificaitonMail() function when a new user signs up
     *
     * @param email - the user inputted email
     * @param password - the user inputted password
     */
    signUp(name: string, email: string, password: string): Promise<FbAuthType>  {
        const promiseToSignUp: Promise<FbAuthType> = this.getFirebaseAuthInstance().createUserWithEmailAndPassword(email, password);

        promiseToSignUp
            .then((result: any) => {
                this.sendVerificationMail();
                this.setUserData( Object.assign({}, result.user,  { displayName: name }) );
            })
            .catch(this.handleError)
        ;

        return promiseToSignUp;
    }

    /**
     * Sign in with email/password
     *
     * @param email - the user inputted email
     * @param password - the user inputted password
     */
    signIn(email: string, password: string): Promise<FbAuthType> {
        const promiseToSignIn: Promise<any> = this.getFirebaseAuthInstance().signInWithEmailAndPassword(email, password);

        // Do any needed manipulation:
        promiseToSignIn
            .then((result: any) => this.setUserData(result.user))
            .catch(this.handleError)
        ;

        // All done? Return the Promise - not the maanipulation result!
        // This way, this Promise can so be chained in any Ng Component that calls this Method Service! I.e. to know when it's Resolved
        return promiseToSignIn;
    }

    get isLoggedIn(): boolean {
        const user: User = JSON.parse(localStorage.getItem('user'));
        // return (user !== null && user.emailVerified !== false) ? true : false;
        return user !== null;
    }

    get isMailValidated(): boolean {
        const user: User = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false) ? true : false;
    }
    // Just for Jasmine Unit Test to be able to launch a Spy on this getter - transform into a Function:
    isLoggedInEmailValidated(): boolean { return this.isMailValidated; }

    /**
     * Have to account for the possibility of the User being already authenticated, with some login in the pass,
     * and, at the bootstrap of the Application / refresh of the page, Firebase already has it authenticated but we don´t.
     * Or the other way around - a coockis still exists, but for some reason, Firebase disconnected the User...
     * So... Check it here, if both at local storage adn REDUX Store, there's some - and it's the SAME! - logged user
     *
     * @param firebaseAuthUser - the (if logged in) user full properties, registered on Firebase Auth
     * @return - a boolean:
     * If "true", user IS in fact logged in, on both Firestore, Local Storage and at the REDUX Store;
     * If "false", actions are here taken to make sure user is NOT logged in, in ALL of them
     */
    checkLoginSyncFirebaseVsLocalStorage(firebaseAuthUser: FirebaseUser): boolean {
        // User is logged in @ Local Storage
        if (this.isLoggedIn) {
            const user: User = JSON.parse(localStorage.getItem('user'));

            if (firebaseAuthUser) {

                if (firebaseAuthUser.uid === user.uid) {
                    return true;
                } else {
                    this.signOut();
                    return false;
                }

            } else {
                // Something is wrong on Google's Firebase - we have a local stored user, but no firebase user
                // or an hacked cookie was stored on browser's local storage...
                // Clear User EVERYWHERE:
                this.signOut();
                return false;
            }

        // User is NOT logged in @ Local Storage
        } else {

            if (firebaseAuthUser) {
                // Something is wrong on browser's Local Storage. Waste no more time:
                this.signOut();
                return false;
            } else {
                return false;
            }

        }
    }

    /**
     * Reset forggotten password
     *
     * @param passwordResetEmail - the user inputted email to reset the password
     */
    forgotPassword(passwordResetEmail: string): Promise<void> {
        return this.getFirebaseAuthInstance().sendPasswordResetEmail(passwordResetEmail)
            .catch(this.handleError)
        ;
    }

    /**
     * Sign out - remove current user on browser's Local Storage and @ the Redux Store
     * Don't forget to send the user to the root - he/she might have been in an Auth page...
     *
     */
    signOut(): Promise<void> {
        // return this.getFirebaseAuthInstance().signOut()
        //     .then(() => {
        //         localStorage.removeItem('user');
        //         this.store.dispatch( new Logout());
        //         this.router.navigate(['/']);
        //     })
        // ;
        // More elegant:
        const fireBaseLogOut: Promise<void> = this.getFirebaseAuthInstance().signOut();
        // Before return:
        fireBaseLogOut
            .then(() => {
                localStorage.removeItem('user');
                this.store.dispatch( new Logout());
                this.router.navigate(['/']);
            })
        ;
        return fireBaseLogOut;
    }

    /**
     * This was tricky!
     * Try to console user of type FirebaseUser...
     *
     * You'll see, among the "useful" properties (of OUR FirebaseUser from 'src/app/app-core/app-core.interfaces')
     * a lot of many others which are not manipulated as an Object/JSON.
     *
     * If you want to merge custom properties onto a Firebase user, you must add toJSON() first:
     * Check it here: https://stackoverflow.com/a/57331186/2816279
     *
     * @param user - the tricky FirebaseUser from Google's Firebase! (NOT our plain, 'scalar', useful, OurFirebaseUser)
     */
    logginUserInReduxStore(user: FirebaseUser): void {
        // This "purged" Object has NO pre-defined Type! ;-) Typescript only accepts 'any' => need to cast it
        const cleanedUser = user.toJSON() as OurFirebaseUser;
        // Set, into 'app-core' Redux Store ('app-core/redux-store'), the Google's Firebase logged IN User data
        this.store.dispatch( new LoginComplete(cleanedUser));

    }

    // --------------------------
    // AUX methods
    // --------------------------

    /**
     * Authoring logic to run Google auth providers
     *
     * @param provider - some Google auth provider
     */
    private authLogin(provider: firebase.auth.GoogleAuthProvider): Promise<FbAuthType> {
        const promiseToAuthSignIn: Promise<any> = this.getFirebaseAuthInstance().signInWithPopup(provider);

        promiseToAuthSignIn
            .then((result: any) => this.setUserData(result.user))
            .catch(this.handleError)
        ;

        return promiseToAuthSignIn;
    }

    /**
     * Send email verfificaiton when new user signs up
     */
    private sendVerificationMail(): Promise<void> {
        return this.currentAuthoredFirebaseUser.sendEmailVerification()
            .catch(this.handleError)
        ;
    }

    /**
     * Setting up user data when sign in with username/password,
     * sign up with username/password
     * or sign in with social auth provider in Firestore database using AngularFirestore + AngularFirestoreDocument service
     *
     * Save any logged user on the browser's localstorage.
     *
     */
    private setUserData(user: FirebaseUser): Promise<void> {

        const userData: User = {
            uid: user.uid,
            email: user.email
                        ||
                        'anon@xyz.Miles-NET.com',
            displayName: user.displayName
                        ||
                        '<i class = "fa fa-user-secret text-secondary"></i>',
            photoURL: user.photoURL
                        ||
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/220px-User_icon_2.svg.png',

            emailVerified: user.emailVerified,
            loginSource: user.isAnonymous ? 'anonymously' : user.providerData[0].providerId
        };

        // Memorize it, so we can UI/UX it:
        localStorage.setItem('user', JSON.stringify(userData));

        // Set, into 'app-core' Redux Store ('app-core/redux-store'), the Google's Firebase logged IN User data
        this.logginUserInReduxStore(user);

        // Send the manipulated user data to Google's Firebase, so we can see it returned (?!?!?! Will it work this way...?)
        // console.warn(userRef);    <= I don't think so! :-(
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        return userRef.set(userData, { merge: true });
    }

    private handleError(error: Error) {
        console.error('==============================');
        console.warn('An authentication ERROR occurred: ', error.message);
        console.error('==============================');
    }

}
