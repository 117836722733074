import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgXyzUiLibModule } from 'ng-xyz-ui-lib';

import { TodosRoutingModule } from './todos.routing';

import { TodosComponent } from './todos.component';

@NgModule({
    imports: [
        CommonModule,

        // Remember this is a lazy loading Module
        // - nothing is known, from it's needs, wwhile it doesn't get URL hited.
        // Must be 100% autonomous!
        // ----------------
        NgXyzUiLibModule,
        // ----------------

        TodosRoutingModule
    ],

    declarations: [
        TodosComponent
    ]
})
export class TodosModule { }
