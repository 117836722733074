import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild, CanLoad, Route
} from '@angular/router';
import { AuthService } from 'src/app/app-core/auth/firebase-auth.services';

@Injectable()
// export class AuthEmailGuard implements CanActivate, CanActivateChild, CanLoad { // <= No routing childrens nor modules to load
export class AuthEmailGuard implements CanActivate {

    constructor(protected authService: AuthService, protected router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;

        return this.checLoginAndEmail(url);
    }

    // -------------------------
    // Not, never, used nor on 'src\app\app.routing.ts'
    // nor on 'src\app\app-core\app-core.routing.ts' nor on 'src\app\app-features\todos\todos.routing.ts'
    // -------------------------
    // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //     return this.canActivate(route, state);
    // }

    // canLoad(route: Route): boolean {
    //     const url = `/${route.path}`;

    //     return this.checLoginAndEmail(url);
    // }
    // -------------------------

    checLoginAndEmail(url: string): boolean {

        if (this.authService.isLoggedIn && this.authService.isMailValidated) { return true; }

        // Navigate to the login page
        this.router.navigate(['/']);
        // the above navigation automatically cancels the current navigation, return false just to be clear
        return false;
    }
}
