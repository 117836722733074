import { createSelector, createFeatureSelector, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';

import { IMainAppModulesState, IAuthenticatedUser } from './store-pack.state.interfaces';
import { appModulesReducerName } from './store-pack.reducers';

// tslint:disable: max-line-length

const getAppModulesState: MemoizedSelector<
    object, IMainAppModulesState, DefaultProjectorFn<IMainAppModulesState>
> = createFeatureSelector<IMainAppModulesState>(appModulesReducerName);

export let appRouter: any;
export let authenticationOfAnUser: any;
export let firebaseUser: any;

// console.log(getAppModulesState);
if (getAppModulesState) {
    // Redux Store SELECTORS, for the main App's 'app-modules' redux-store:
    // --------------------------------------------
    appRouter = createSelector( getAppModulesState, (state: IMainAppModulesState) => state.router);
    // --------------------------------------------

    // AUTH of a login, slice of the Redux State:
    // --------------------------------------------
    authenticationOfAnUser = createSelector( getAppModulesState, (state: IMainAppModulesState) => state.authenticationOfAnUser);
    // --------------------------------------------
    // Props of the State, that might, or not, EVER exist:
    firebaseUser = createSelector( getAppModulesState, (state: IMainAppModulesState) => state.authenticationOfAnUser.authData);
}

// If someone is interested to know which TYPE does a 'createSelector()' returns... here it is:
// export const authenticationOfAnUser: MemoizedSelector<object, IAuthenticatedUser, DefaultProjectorFn<IAuthenticatedUser>> = createSelector( getAppModulesState, (state: IMainAppModulesState) => state.authenticationOfAnUser);
// We can forget it... it's on the declaration of the Observable variable, atributed to each of these State Selectors, that we strongly enforce TypeScript.
