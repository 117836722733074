import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Google's Firebase dependencies
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';

// REDUX affairs:
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// .... and some fancy options:
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

// Modules:
import { AppRoutingModule } from './app.routing';
import { AppCoreModule } from './app-core/app-core.module';

// Components
import { AppComponent } from './app.component';

@NgModule({
    imports: [
        BrowserModule,

        // ----------- Google's Firebase dependencies -----------
        // imports firebase/app, needed for everything, from our Firebase project id (optional):
        AngularFireModule.initializeApp(environment.firebaseConfig),
        // imports firebase/auth, only needed for auth features,
        AngularFireAuthModule,
        // imports firebase/firestore, only needed for database features
        // (things like working with collections, queries, services for data streaming and manipulation, etc.)
        AngularFirestoreModule,
        // imports firebase/storage only needed for storage features
        // (store and serve user-generated content like photos and videos as well as monitor uploads and metadata associated with files)
        // AngularFireStorageModule,
        // -------------------------------------------------------

        // ----------- Ngrx REDUX config -----------
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        // --------------------------
        StoreDevtoolsModule.instrument({ maxAge: 15 }),
        // Stuff Angular's routes into the App's Store:
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        // To use Chrome browser extension called Redux DevTools:
        // !environment.production ? StoreDevtoolsModule.instrument() : [],
        // --------------------------

        AppCoreModule,

        // BE CAREFULL with MODULE's import ORDER!
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        // HomepageComponent    // <= delared and exported on AppCoreModule
    ],
    providers: [
        AngularFirestore
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
