<button href="javascript: void(0)" class="btn btn-sm btn-outline-info mt-4" routerLink="" title="Take me Home!">
    <i class="fa fa-long-arrow-left mr-3" aria-hidden="true"></i>
    back to home
</button>

<div class="card mild-shadow mt-4" *ngIf="(user$ | async) as user">
    <div class="card-body">
        <pre>{{ user | json }}</pre>
    </div>
</div>

