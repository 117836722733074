
<!-- See all Observables content! -->
<ng-container *ngIf="activateDebugConsole">

    <div class="user-id-fixed-container container mt-3" style="
        position: fixed;
        z-index: 2;
        background-color:rgb(0,0,0, 0.03);
        width: calc(100% - 60px);
        padding: 1rem 3rem;
    ">
        Logged IN User UID:
        <h3><div class="badge badge-danger">{{ (theAuth$ | async)?.authData?.uid}}</div></h3>        
    </div>
    <div style="margin-top: 9rem;"></div>

    <ng-container *ngIf="(todos$ | async) as allTodos">
        <button class="btn btn-sm btn-primary" type="button" data-toggle="collapse" data-target="#allTodos" aria-expanded="false" aria-controls="allTodos">
            ALL TODOs ({{ allTodos.count }})
        </button>
        <div class="collapse" id="allTodos">
            <div class="card card-body">
                <pre>{{ allTodos | json }}</pre>
            </div>
        </div>
    </ng-container>
    <hr>

    <ng-container *ngIf="(todosForThisUser$ | async) as userTodos">
        <button class="btn btn-sm btn-primary" type="button" data-toggle="collapse" data-target="#thisUserTodos" aria-expanded="false" aria-controls="thisUserTodos">
            this User TODOs ({{ userTodos.count }})
        </button>
        <div class="collapse" id="thisUserTodos">
            <div class="card card-body">
                <pre>{{ userTodos | json }}</pre>
            </div>
        </div>
    </ng-container>
    <hr>

    <ng-container *ngIf="(this.fbDBService.authDoneFilteringTodos$ | async) as fbServiceTodos">
        <button class="btn btn-sm btn-primary" type="button" data-toggle="collapse" data-target="#serviceTodos" aria-expanded="false" aria-controls="serviceTodos">
            Fb Service TODOs filtered ({{ fbServiceTodos.count }})
        </button>
        <div class="collapse" id="serviceTodos">
            <div class="card card-body">
                <pre>{{ fbServiceTodos | json }}</pre>
            </div>
        </div>
    </ng-container>
    <hr>

</ng-container>
<!-- /See all Oservables content! -->


<main *ngIf="(theAuth$ | async)?.authData as firebaseUser" class="listing-feed mt-5">    
    <!-- <p *ngIf="(todosForThisUser$ | async)?.count === 0" class="mt-3">You should try your first TODO! It's so much fun...!</p> -->
    <xyz-todo-form-create (newTodo)="onTodoCreation($event, firebaseUser.uid)"></xyz-todo-form-create>

    <h1 class="text-center text-uppercase">
        <div class="d-flex flex-column">
            <div class="btn btn-outline-info mb-3 filter-buttons-title">My TODOs Feed</div>
            <div class="btn-group filter-buttons" role="group" aria-label="Choose Done or Todo">
                <button type="button" class="btn btn-sm btn-success mx-2 text-uppercase" (click)="filterItemsList('dones')">Done(s)</button>
                <button type="button" class="btn btn-sm btn-danger mx-2 text-uppercase" (click)="filterItemsList('todos')">Todo(s)</button>
                <button type="button" class="btn btn-sm btn-info mx-2 text-uppercase" (click)="filterItemsList('all')">All</button>
            </div>
        </div>
    </h1>
    
    <div class="row">
        <ng-container *ngFor="let todo of (todos$ | async)">
            <div *ngIf="
                todo && todo.userId === firebaseUser.uid && filterDonePropTo === null
                ||
                todo && todo.userId === firebaseUser.uid && (filterDonePropTo === true && todo.done === true)
                ||
                todo && todo.userId === firebaseUser.uid && (filterDonePropTo === false && todo.done === false) 
            " class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <xyz-todo-form-edit [itemTodo]="todo" (manipulatedTodo)="onTodoManipulation($event)"></xyz-todo-form-edit>
            </div>
        </ng-container>
    </div>

</main>