import { ActionReducerMap } from '@ngrx/store';

import { IMainAppModulesState } from './store-pack.state.interfaces';

import { routerReducer } from '@ngrx/router-store';
import { authenticationReducers } from './reducers/authentication.reducers';

export const appModulesReducerName = 'app-core';
export const appModulesReducers: ActionReducerMap<IMainAppModulesState, any> = {
    router: routerReducer
    , authenticationOfAnUser: authenticationReducers
};
